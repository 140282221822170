import React from 'react';

const Footer = () => (
  <footer
    style={{
      width: '100%',
      background: 'rebeccapurple',
      color: '#fff',
      textAlign: 'center'
    }}
  >
    This is a footer
  </footer>
);

export default Footer;
